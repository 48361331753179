import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

// SOURCE: Directly from https://v4.mui.com/components/transfer-list/#enhanced-transfer-list

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  marginLeft: {
    marginLeft: 25,
  },
  marginRight: {
    marginRight: 25,
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 200,
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const getIndexIncrement = (direction) => {
  switch (direction) {
    case 'up':
      return -1;
    case 'down':
      return 1;
    default:
      return 0;
  }
};

const sortSelected = (selected, direction) => {
  switch (direction) {
    case 'up':
      return selected.sort((a, b) => a.index - b.index);
    case 'down':
      return selected.sort((a, b) => b.index - a.index);
    default:
      throw Error(`Direction ${direction} not one of options: ['up','down']`);
  }
};

const TransferList = ({
  left = [],
  setLeft = (f) => f,
  right = [],
  setRight = (f) => f,
  leftText = 'Left',
  rightText = 'Right',
}) => {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const shift = (selected, all, setter, direction) => {
    // generate potential new index location
    let selectedIndices = selected.map((text) => ({
      text,
      index: all.indexOf(text),
      newIndex: all.indexOf(text) + getIndexIncrement(direction),
    }));
    selectedIndices = sortSelected(selectedIndices, direction);

    // adjust indices to be within the array bounds
    const converted = new Set();
    selectedIndices = selectedIndices.map(({ text, index, newIndex }) => {
      let updatedNewIndex = newIndex;
      switch (direction) {
        case 'up':
          while (converted.has(updatedNewIndex) || updatedNewIndex < 0) {
            updatedNewIndex += 1;
          }
          break;
        case 'down':
          while (converted.has(updatedNewIndex) || updatedNewIndex >= all.length) {
            updatedNewIndex -= 1;
          }
          break;
        default:
          throw Error(`Direction ${direction} not one of options: ['up','down']`);
      }
      converted.add(updatedNewIndex);
      return {
        text,
        index,
        newIndex: updatedNewIndex,
      };
    });

    const tmp = all;
    selectedIndices.forEach(({ text, index, newIndex }) => {
      // remove from current index
      tmp.splice(index, 1);
      // insert at new index
      tmp.splice(newIndex, 0, text);
    });

    setter([...tmp]);
  };

  const handleMoveUp = () => {
    shift(leftChecked, left, setLeft, 'up');
    shift(rightChecked, right, setRight, 'up');
  };

  const handleMoveDown = () => {
    shift(leftChecked, left, setLeft, 'down');
    shift(rightChecked, right, setRight, 'down');
  };

  const handleDeleteSelected = () => {
    setLeft(not(left, leftChecked));
    setRight(not(right, rightChecked));
    setChecked(not(not(checked, rightChecked), leftChecked));
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={
              numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.root}>
      <Grid item className={classes.marginRight}>
        {customList(leftText, left)}
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleMoveUp}
            disabled={leftChecked.length === 0 && rightChecked.length === 0}
            aria-label="move selected up"
          >
            <ExpandLessIcon />
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleMoveDown}
            disabled={leftChecked.length === 0 && rightChecked.length === 0}
            aria-label="move selected down"
          >
            <ExpandMoreIcon />
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleDeleteSelected}
            disabled={leftChecked.length === 0 && rightChecked.length === 0}
            aria-label="delete selected"
          >
            Delete Selected
          </Button>
        </Grid>
      </Grid>
      <Grid item className={classes.marginLeft}>
        {customList(rightText, right)}
      </Grid>
    </Grid>
  );
};

TransferList.propTypes = {
  left: PropTypes.array,
  setLeft: PropTypes.func,
  right: PropTypes.array,
  setRight: PropTypes.func,
  leftText: PropTypes.string,
  rightText: PropTypes.string,
};

export default TransferList;
