import axios from 'axios';

import { MAX_RULE_STRENGTH } from '../config';
import {
  MODELS_ALL_FILTERED_ENDPOINT,
  MODELS_BASICS_ENDPOINT,
  MODELS_COUNT_TRAINING_ENDPOINT,
  MODELS_DOWNLOAD_TSV_FILE_ENDPOINT,
  MODELS_ENDPOINT,
  MODELS_RULES_ENDPOINT,
  MODELS_UNASSIGNED_TRAINING_ENDPOINT,
} from '../endpoints.config';
import { sortModels } from '../utils/modelDisplayUtils';

export function GetModels(headers) {
  return axios
    .get(MODELS_ENDPOINT, { headers })
    .then((res) => sortModels(res.data))
    .catch((e) => {
      console.log(e);
    });
}

export async function GetTrainingModelRules(headers, id) {
  const response = await axios
    .get(`${MODELS_RULES_ENDPOINT(id)}`, { headers })
    .catch((err) => {
      console.log('Error! Failed to retrieve training model rules with error:', err);
      throw err;
    });
  if (response.status !== 200 || !response.data) {
    throw Error('No rules found');
  }
  return response.data;
}

export async function GetAllFilteredModels(headers) {
  return axios
    .get(`${MODELS_ALL_FILTERED_ENDPOINT}`, { headers })
    .then((res) => {
      const { data } = res;
      return data;
    })
    .catch((err) => {
      throw err;
    });
}

export async function GetModelBasics(headers, id) {
  const response = await axios
    .get(`${MODELS_BASICS_ENDPOINT(id)}`, { headers })
    .catch((err) => {
      console.log(
        'Error! Failed to retrieve training model data basics with error:',
        err
      );
      throw err;
    });
  if (response.status !== 200 || !response.data) {
    throw Error('No model data found');
  }
  return response.data;
}

export async function GetModelTrainingCount(headers, id) {
  const response = await axios
    .get(`${MODELS_COUNT_TRAINING_ENDPOINT(id)}`, { headers })
    .catch((err) => {
      console.log(
        'Error! Failed to retrieve training model training count with error:',
        err
      );
      throw err;
    });
  if (response.status !== 200 || !response.data) {
    throw Error('No training counts found');
  }
  return response.data;
}

export async function CheckForUnassignedTrainingData(headers, id) {
  const response = await axios
    .get(`${MODELS_UNASSIGNED_TRAINING_ENDPOINT(id)}`, { headers })
    .catch((err) => {
      console.log('Error! Failed to check for unassigned training data with error:', err);
      throw err;
    });
  if (response.status !== 200 || response.data === null || response.data === undefined) {
    throw Error('Invalid response');
  }
  return response.data;
}

export async function FixUnassignedTrainingData(headers, id, ruleStrengthDivisor) {
  const response = await axios
    .put(
      `${MODELS_UNASSIGNED_TRAINING_ENDPOINT(id)}`,
      {
        ruleStrengthDivisor,
        ruleStrengthMax: MAX_RULE_STRENGTH,
      },
      { headers }
    )
    .catch((err) => {
      console.log('Error! Failed to fix unassigned training data with error:', err);
      throw err;
    });
  if (response.status !== 200) {
    throw Error(
      `Failed to fix unassigned training data with ${response.status} ${response.statusText}`
    );
  }
}

export async function DownloadAsTsv(headers, id, file) {
  const response = await axios
    .get(`${MODELS_DOWNLOAD_TSV_FILE_ENDPOINT(id)}?file=${file}`, {
      headers,
      responseType: 'blob',
    })
    .catch((err) => {
      console.log('Error! Failed to download csv file with error:', err);
      throw err;
    });
  if (response.status !== 200) {
    throw Error('Invalid response');
  }
  return response.data;
}
