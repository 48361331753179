import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import withStyles from '@mui/styles/withStyles';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Box,
  Chip,
  Button,
  Typography,
} from '@mui/material';

import AnswerQuestionsSelectSectionStyles from './styles';
import { useAuthUpdateContext } from '../../auth/AuthUpdateProvider';
import {
  SetQuestionnaire,
  SetSectionStatusesIncomplete,
  SetSelectedQuestions,
} from '../../redux/actions/answerQuestionsActions';
import { setErrMsg } from '../../redux/actions/snackbarActions';
import { GetDefaultQuestionnaireByModelId } from '../../requests/questionnaire';
import Colors from '../../styles/Colors';
import PlaybookBuilderStyles from '../../styles/PlaybookBuilderStyles';
import combineStyles from '../../utils/CombineStyles';
import { PlaybookBuilderPage, BaseDialogPage } from '../../components';

function AnswerQuestionSectionSelection({ classes, modelId, handleNext, isOnboarding }) {
  const { getAuthHeader } = useAuthUpdateContext();
  const dispatch = useDispatch();
  const { questionnaire, selectedQuestions } = useSelector(
    (state) => state.AnswerQuestions
  );
  const [sectionsCollapsed, setSectionsCollapsed] = useState({});

  const setErr = (value) => dispatch(setErrMsg(value));

  useEffect(() => {
    getAuthHeader().then((headers) => {
      GetDefaultQuestionnaireByModelId(headers, modelId)
        .then((questionnaireData) => {
          dispatch(SetQuestionnaire(questionnaireData));
        })
        .catch((err) => {
          console.error(err);
          setErr('Failed to retrieve questionnaires');
        });
    });
  }, []);
  -useEffect(() => {
    if (!questionnaire?.sections) {
      return;
    }
    setSectionsCollapsed((currentCollapsed) =>
      questionnaire.sections
        .map(({ _id }) => _id)
        .reduce((acc, currId) => {
          acc[currId] = currentCollapsed?.[currId] || true;
          return acc;
        }, {})
    );
  }, [questionnaire?.sections]);

  function getSectionQuestions(section) {
    return questionnaire.questions.filter((q) => q.section === section);
  }

  function handleSelectAll() {
    if (isAllSelected()) {
      dispatch(SetSelectedQuestions([]));
    } else {
      dispatch(SetSelectedQuestions(questionnaire?.questions));
    }
  }

  function isAllSelected() {
    return !!questionnaire?.questions?.every((q) =>
      selectedQuestions.find((sq) => sq._id === q._id)
    );
  }

  function isSectionSelected(section) {
    const sectionQuestions = getSectionQuestions(section);
    return sectionQuestions.every((q) =>
      selectedQuestions.find((sq) => sq._id === q._id)
    );
  }

  function isSectionIndeterminate(section) {
    const sectionQuestions = getSectionQuestions(section);
    return (
      !isSectionSelected(section) &&
      sectionQuestions.some((q) => selectedQuestions.find((sq) => sq._id === q._id))
    );
  }

  function handleExpandSection(sectionId) {
    setSectionsCollapsed((currentCollapsed) => ({
      ...currentCollapsed,
      [sectionId]: !currentCollapsed[sectionId],
    }));
  }

  function handleSelectSection(sectionId) {
    if (isSectionSelected(sectionId)) {
      dispatch(
        SetSelectedQuestions(selectedQuestions.filter((q) => q.section !== sectionId))
      );
    } else {
      const selectedIds = selectedQuestions.map((sq) => sq._id);
      const idSet = new Set(selectedIds);
      const sectionIdSet = new Set(
        getSectionQuestions(sectionId).map((sectQ) => sectQ._id)
      );
      const unionSet = idSet.union(sectionIdSet);
      const selected = questionnaire.questions.filter((q) => unionSet.has(q._id));
      dispatch(SetSelectedQuestions(selected));
    }
  }

  function handleQuestionSelect(question, isChecked) {
    const selectedQSet = new Set(selectedQuestions.map((q) => q._id));
    if (isChecked) {
      selectedQSet.add(question._id);
    } else {
      selectedQSet.delete(question._id);
    }
    const selectedQs = questionnaire.questions.filter((q) => selectedQSet.has(q._id));
    dispatch(SetSelectedQuestions(selectedQs));
  }

  function questionsBySection(sectionId) {
    return questionnaire?.questions.filter((q) => q.section === sectionId);
  }

  function questionsBySectionSelected(sectionId) {
    return selectedQuestions.filter((q) => q.section === sectionId);
  }

  function startLink() {
    const selectedSectionIds = Array.from(
      new Set(selectedQuestions.map((sq) => sq.section))
    );
    const selectedSections = questionnaire.sections.filter((s) =>
      selectedSectionIds.includes(s._id)
    );
    const unselectedSections = questionnaire.sections.filter(
      (s) => !selectedSectionIds.includes(s._id)
    );
    dispatch(
      SetSectionStatusesIncomplete(
        selectedSections,
        unselectedSections,
        selectedQuestions
      )
    );
    handleNext();
  }

  const renderContent = (
    <>
      <Grid container alignItems="center" className={classes.selectAllPadding}>
        <Button onClick={handleSelectAll} className={classes.selectAllCheckbox}>
          <Grid item>
            <Checkbox
              style={{ color: Colors.blue }}
              color="primary"
              name="Select All"
              checked={isAllSelected()}
            />
          </Grid>
          <Grid>
            <Typography className={classes.sectionText}>Select All</Typography>
          </Grid>
        </Button>
      </Grid>
      <Grid container direction="column">
        {!!questionnaire &&
          questionnaire.sections.map(({ _id: sectionId, name: sectionName }) => (
            <React.Fragment key={sectionId}>
              <Grid item sx={{ mt: 2 }}>
                <Button
                  onClick={() => handleExpandSection(sectionId)}
                  className={classes.checkboxButtonWrapper}
                >
                  <Grid container alignItems="center">
                    <Grid item>
                      <Checkbox
                        color="primary"
                        checked={isSectionSelected(sectionId)}
                        indeterminate={isSectionIndeterminate(sectionId)}
                        name={sectionName}
                        disabled={getSectionQuestions(sectionId).length === 0}
                        onChange={() => handleSelectSection(sectionId)}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" className={classes.sectionText}>
                        {sectionName}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box>
                    <Chip
                      label={`${questionsBySectionSelected(sectionId).length} / ${questionsBySection(sectionId).length}`}
                      size="small"
                    />
                  </Box>
                </Button>
              </Grid>
              {!!questionnaire.questions &&
                questionnaire.questions
                  .filter((q) => q.section === sectionId && !sectionsCollapsed[sectionId])
                  .map((question) => {
                    const {
                      _id: questionId,
                      text: questionText,
                      description: questionDescription,
                    } = question;
                    return (
                      <Grid
                        item
                        key={questionId}
                        className={classes.innerCheckboxWrapper}
                      >
                        <FormControlLabel
                          sx={{ ml: 4, my: 2 }}
                          label={
                            <>
                              <Box>
                                <Typography variant="body2">{questionText}</Typography>
                              </Box>
                              <Box>
                                <Typography variant="body1">
                                  {questionDescription}
                                </Typography>
                              </Box>
                            </>
                          }
                          control={
                            <Checkbox
                              color="primary"
                              checked={
                                !!selectedQuestions.find((sq) => sq._id === questionId)
                              }
                              name={questionText}
                              onChange={(evt) =>
                                handleQuestionSelect(question, evt.target.checked)
                              }
                            />
                          }
                        />
                      </Grid>
                    );
                  })}
            </React.Fragment>
          ))}
      </Grid>
    </>
  );

  if (isOnboarding) {
    return (
      <BaseDialogPage
        title="Answer Questions:"
        extendedTitle="Select the sections you commonly edit to get started"
        description={
          `This determines the types of questions that you will answer` +
          ` to build the rules for editing your NDA model in the future.` +
          ` Don't worry, you can add more sections later.`
        }
        rButtonText="Start"
        rButtonOnClick={startLink}
        showLButton={false}
        showTopNav={false}
      >
        {renderContent}
      </BaseDialogPage>
    );
  }

  return (
    <PlaybookBuilderPage
      title="Answer Questions:"
      extendedTitle="Select the sections you commonly edit to get started"
      description={
        `This determines the types of questions that you will answer` +
        ` to build the rules for editing your NDA model in the future.` +
        ` Don't worry, you can add more sections later.`
      }
      rButtonText="Start"
      rButtonOnClick={startLink}
      showLButton={false}
    >
      {renderContent}
    </PlaybookBuilderPage>
  );
}

AnswerQuestionSectionSelection.propTypes = {
  classes: PropTypes.object.isRequired,
  handleNext: PropTypes.func.isRequired,
  modelId: PropTypes.string.isRequired,
  isOnboarding: PropTypes.bool,
};

const combinedStyles = combineStyles(
  PlaybookBuilderStyles,
  AnswerQuestionsSelectSectionStyles
);

export default withStyles(combinedStyles)(AnswerQuestionSectionSelection);
